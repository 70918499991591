@media only screen and(max-width:475px){
  .switch {
    height: 3.4vh !important;
  }
}
.switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 22px;
  }
  
  .switch input { 
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border:1px solid #454643 !important;
    background-color: black;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 17.5px;
    width: 17.5px;
    left: 3px;
    bottom: 2px;
    top: 2px;
    border-width: 1px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .slider {
      border: gray;
      border-width: 2px;
    background-color: #4CD964;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 0px #4CD964;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(15px);
    -ms-transform: translateX(15px);
    transform: translateX(15px);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
    border-width: 1px;
  }