body {
  margin: 0 auto;
  padding: 0;
  background-color: black;
  box-sizing: border-box;
}
.container{
  margin: 0 auto;
  width: 1100px;
}
#logoImg {
  background-image: url("../assets/images/logo.png");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
body::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
body {
-ms-overflow-style: none;  /* IE and Edge */
scrollbar-width: none;  /* Firefox */
}
.nobar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.nobar {
-ms-overflow-style: none;  /* IE and Edge */
scrollbar-width: none;  /* Firefox */
}
.nobar{
  border: none;
  overflow: auto;
  top: '10%';
  position: 'absolute';
  left: '25%';
  right: 'auto';
  bottom: 'auto';
  width: "54.4%";
  height: '80%';
  margin: "0 auto";
  padding: "10px";
  background-color: "#232321";
  border: "0 px";
}


.customBar::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.customBar::-webkit-scrollbar-track {
  background: #D8D8D8D8; 
}
 
/* Handle */
.customBar::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
.customBar::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}

.separated-row {
  border-spacing: 0 5px;
  border-collapse: separate;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.floating-tooltip-2 {
	width: 96px;
	height: 80px;
	position: absolute;
	display: none;
	padding: 8px;
	box-sizing: border-box;
	font-size: 12px;
	color: #131722;
	background-color: rgba(255, 255, 255, 1);
	text-align: left;
	z-index: 1000;
	top: 12px;
	left: 12px;
	pointer-events: none;
	border: 1px solid rgba(0, 150, 136, 1);
	border-radius: 2px;
}
