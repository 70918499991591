.main-holder
{
    padding: 10px;
    background: #fff;
}

.main-holder > h1
{
    text-align: center;
}
h1 {
    color: '#fff'
}

p {

}